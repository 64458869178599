import React from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { useTela } from '../../../TelaContext';
import { useModulos } from '~/pages/CadastroDeTela/data/Modulo';

export const ModuloContainer: React.FC = () => {
  const { formTela } = useTela();

  const { register, formState, control, setValue } = formTela;
  const { errors } = formState;

  const { modulosAtivos } = useModulos();

  // Ordenar os módulos em ordem alfabética
  const sortedModulos = React.useMemo(
    () =>
      modulosAtivos
        .slice()
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
        ),
    [modulosAtivos],
  );

  return (
    <>
      <InputSelect
        label="Módulo"
        placeholder="- Selecione o módulo -"
        name="cod_modulo"
        register={register}
        isError={!!errors.cod_modulo}
        control={control}
        options={sortedModulos}
        changeSelected={(selected) => setValue('cod_modulo', selected)}
      />
    </>
  );
};

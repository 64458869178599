import { CustomButton } from '~/components/Buttons';
import { InputText } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useTiposDados } from '../../data/TiposDados';
import { useTiposInformacoes } from '../../data/TiposInformacoes';
import { useTela } from '../../TelaContext';
import { extractQueryFields } from '../../utils/ExtractFieldsFromSql';
import { Consulta } from '../Consulta';
import { IconContainer } from '../Containers/IconContainer';
import { LibsIconsContainer } from '../Containers/LibsIconsContainer';
import { ModuloContainer } from '../Containers/ModuloContainer';
import { ContainerRow } from './styles';

export const Form: React.FC = () => {
  const { formTela, isUpdate } = useTela();

  const MySwal = withReactContent(Swal);

  const {
    watch,
    resetField,
    setValue,
    getValues,
    formState,
    register,
    control,
  } = formTela;
  const { errors } = formState;

  const { tipoDados } = useTiposDados();

  const { tiposInformacoes } = useTiposInformacoes();

  const des_icon = watch('des_icon');
  const watchFlgRequerSuperAdmin = watch('flg_requer_superadmin');
  const watchFlgRequerAdmin = watch('flg_requer_admin');
  const watchFlgInativo = watch('flg_inativo');
  const watchFlgAbrePesquisa = watch('flg_abre_pesquisa');

  useEffect(() => {
    if (watchFlgRequerSuperAdmin) {
      setValue('flg_requer_admin', true);
    }
  }, [watchFlgRequerSuperAdmin]);

  useEffect(() => {
    resetField('flg_requer_superadmin', { defaultValue: false });
    resetField('flg_requer_admin', { defaultValue: false });
    resetField('flg_inativo', { defaultValue: false });
  }, []);

  const onGenerateFieldsClick = async () => {
    const { campos, cod_tela } = getValues();

    if (campos.length >= 1) {
      await MySwal.fire({
        icon: 'warning',
        title: ``,
        text: 'Deseja realmente adicionar os campos a partir da consulta atual? \n Isso irá deletar todos os campos já adicionados!',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (isUpdate) {
            const codCampos = campos.map((campo: any) => campo.cod_campo);

            await api.post(`/tela/check-campos-filtros`, {
              cod_tela,
              campos: codCampos,
            });
            deleteAllFields();
            generateFieldsFromSql();
            return;
          }
          setValue('campos', []);
          generateFieldsFromSql();
        }
      });
    } else {
      generateFieldsFromSql();
    }
  };

  const deleteAllFields = () => {
    const { campos } = getValues();

    campos.map((campo: any) => {
      campo.action = 'delete';
      return campo;
    });

    setValue('campos', campos);
  };

  const generateFieldsFromSql = async () => {
    const sql = getValues('instrucao');
    const queryFields = extractQueryFields(sql);

    const res = await api.post('/tela/gerar-campos', { query: queryFields });
    let { data } = res.data;

    const campos = getValues('campos');

    let count = campos.length - 1;

    data = data.map((item: any) => {
      count++;
      const codTipoDados = tipoDados.find(
        (tipo) => tipo.value === item.cod_tipo_dados,
      );

      const codTipoInfo = tiposInformacoes.find(
        (tipo) => tipo.value === item.cod_tipo_info,
      );

      const id = nanoid();
      return {
        ...item,
        id,
        num_ordem: count,
        uuid: id,
        cod_tipo_dados: codTipoDados || undefined,
        codTipoInfo: codTipoInfo || undefined,
      };
    });

    if (campos.lengh === 0) {
      return setValue('campos', data);
    }

    const oldValuesCampos = campos.map((campo: any) => {
      return campo.nome_bd;
    });

    const newValuesCampos = data.map((campo: any) => {
      return campo.nome_bd;
    });

    campos.map((campo: any) => {
      if (newValuesCampos.indexOf(campo.nome_bd) > -1) {
        campo.action = '';
      }
      return campo;
    });

    const newValues = data.filter((campo: any) => {
      return oldValuesCampos.indexOf(campo.nome_bd) === -1;
    });

    setValue('campos', [...campos, ...newValues]);
  };

  return (
    <ContainerRow>
      <Row>
        <Col xs={12} sm={12} md={3}>
          <InputText
            label="Nome"
            maxLength={100}
            placeholder="Exibido na barra de pesquisa"
            name="des_tela"
            register={register}
            disabled={false}
            isError={!!errors.des_tela}
            control={control}
          />
        </Col>

        <Col xs={12} sm={12} md={3}>
          <ModuloContainer />
        </Col>

        <Col xs={4} sm={4} md={2} className="togleInativo">
          <ToggleDefault
            labelText="Inativo?"
            setChecked={watchFlgInativo}
            onSwitch={() => {
              setValue('flg_inativo', !watchFlgInativo);
            }}
          />
        </Col>

        <Col xs={4} sm={4} md={2} className="toglePesquisa">
          <ToggleDefault
            labelText="Abre pesquisa?"
            setChecked={watchFlgAbrePesquisa}
            onSwitch={() => {
              setValue('flg_abre_pesquisa', !watchFlgAbrePesquisa);
            }}
          />
        </Col>

        <Col xs={3} sm={4} md={2} className="togleRequerAdmin">
          <ToggleDefault
            labelText="Requer admin?"
            setChecked={watchFlgRequerAdmin}
            onSwitch={() => {
              setValue('flg_requer_admin', !watchFlgRequerAdmin);
            }}
            disabled={watchFlgRequerSuperAdmin}
          />
        </Col>

        <Col xs={4} sm={4} md={2} className="togleRequerSuperAdmin">
          <ToggleDefault
            labelText="Requer superadmin?"
            setChecked={watchFlgRequerSuperAdmin}
            onSwitch={() => {
              setValue('flg_requer_superadmin', !watchFlgRequerSuperAdmin);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <InputText
            label="Descrição da função"
            maxLength={100}
            placeholder="Exibido como segunda linha na barra de pesquisa"
            name="des_funcao"
            register={register}
            autoComplete="false"
            disabled={false}
            control={control}
            isError={!!errors.des_funcao}
          />
        </Col>

        <Col sm={12} md={6}>
          <InputText
            label="Título da tela"
            maxLength={100}
            placeholder="Exibido como título principal ao abrir a tela"
            name="label_menu"
            register={register}
            toLowerCase={false}
            autoComplete="false"
            disabled={false}
            isError={!!errors.label_menu}
            control={control}
          />
        </Col>
      </Row>

      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Col sm={6} md={6} lg={2} xl={2}>
          <LibsIconsContainer />
        </Col>

        <Col sm={6} md={6} lg={2} xl={2}>
          <IconContainer />
        </Col>

        <Col sm={12} md={12} lg={6} xl={8}>
          <InputText
            label="Link do material"
            maxLength={100}
            placeholder="Informe o link do material"
            name="link_material"
            register={register}
            toLowerCase={false}
            disabled={false}
            isError={!!errors.link_material}
            control={control}
          />
        </Col>
      </Row>

      <Row>
        <Col
          sm={12}
          style={{
            position: 'relative',
          }}
        >
          <Consulta />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} className="d-flex align-items-end justify-content-end">
          <CustomButton
            style={{
              backgroundColor: '#8850bf',
            }}
            onClick={onGenerateFieldsClick}
          >
            Gerar campos pela consulta
          </CustomButton>
        </Col>
      </Row>
    </ContainerRow>
  );
};
